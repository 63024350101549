.description_block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 2.5rem;
  background-color: #0f5835;
  color: white;
  height: 25rem;
  margin: 2rem 0;
  border-radius: 12px;
  column-gap: 0.5rem;
  max-width: 650px;
  width: 80%;
}

.left_align img,
.left_align .content {
  animation-name: left_desc_animation;
}

.right_align img,
.right_align .content {
  animation-name: right_desc_animation;
}

.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_container > img {
  z-index: 2;
  height: 100%;
  max-height: 25rem;
  border-radius: 8px;
  cursor: pointer;
  animation-timing-function: linear;
  animation-timeline: view();
}

.content {
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-timing-function: linear;
  animation-timeline: view();
}

@keyframes left_desc_animation {
  from {
    transform: translate(-30%);
  }
  to {
    transform: translate(20%);
  }
}

@keyframes right_desc_animation {
  from {
    transform: translate(30%);
  }
  to {
    transform: translate(-20%);
  }
}

@media (width<700px) {
  .description_block {
    margin: 1.2rem 0;
    /* width: 70%; */
    padding: 0rem 1.5rem;
  }
  .img_container > img {
    max-height: 22rem;
  }
  .content {
    font-size: 1.2rem;
  }
}

@media (width<500px) {
  .img_container > img {
    max-height: 20rem;
  }
  .content {
    font-size: 1.1rem;
  }
}
