.navbar {
  color: white;
  background-color: #0f5835;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 6px 1px rgba(0, 0, 0, 0.64);
  column-gap: 0.8rem;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
}
/* .title {
} */

.logo_img{
  width: 2.6rem;
  border-radius: 0.4rem;
}


@media (width<500px) {
  .title > p {
    font-size: 0.75rem;
  }
}
