@import url("https://fonts.googleapis.com/css2?family=Alata&family=Mountains+of+Christmas:wght@700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,300&display=swap");

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Alata", sans-serif;
}
.home_page_sec{
  width: 100%;
}

.descriptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* margin: 2rem 15%; */
}

/* @media (width<700px) {
  .descriptions {
    margin: 2rem 0;
  }
} */
