.footer {
  /* border: 1px solid black; */
  padding: 2rem 3rem;
  display: flex;
  justify-content: space-between;
  column-gap: 1.5rem;
  margin-top: 5rem;
}
.footer h2 {
  font-size: 1.35rem;
}
.footer > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
}
.contact_sec {
  p {
    cursor: pointer;
  }
  a{
    text-decoration: none;
    color: black;
  }
}

.social_media_icons {
  display: flex;
  column-gap: 1rem;
}
.social_media_icons svg {
  font-size: 1.6rem;
  cursor: pointer;
}

.download_sec {
  align-items: flex-end;
}
.download_images {
  width: max-content;
  display: flex;
  flex-direction: column;
}
.download_images > img {
  width: 150px;
  margin: 0.3rem 0;
  cursor: pointer;
}

@media (width<600px) {
  .footer {
    flex-direction: column;
    row-gap: 2rem;
  }
  .download_sec {
    align-items: flex-start;
  }
}

/* .footer h2{
   text-align: center;
} */
