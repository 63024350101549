.video_player {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  border-radius: 20px;
  overflow: hidden;
}

.video_player video {
  width: 85%;
  border-radius: 20px;
  max-width: 800px;
}

@media (width: <= 500px) {
  .video_player video {
    width: 100%;
    
  }
}
