.privacyPolicy {
  padding: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}
