.header {
  padding: 8rem 6% 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  text-align: center;
  color: #1d382f;
}
.logo_img {
  width: 5rem;
  border-radius: 0.8rem;
}

.header > .download_buttons {
  display: flex;
  column-gap: 2.5rem;
}

.btn_sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.8rem;

  img{
    width: 85%;
    cursor: pointer;
  }
}

.android_rating,
.ios_rating {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  width: 90%;
}

.android_rating > .star_sec,
.ios_rating > .star_sec {
  color: gold;
}

.header_img {
  width: 75%;
  max-width: 600px;
  cursor: pointer;
  z-index: 1;
  animation-name: headerImgAnimation;
  animation-timing-function: linear;
  animation-timeline: view(10%);
}

@keyframes headerImgAnimation {
  to {
    transform: scale(1.3) rotatez(15deg);
  }
}

@media (width <500px) {
  .header {
    padding: 8rem 0 2rem;
  }
}
